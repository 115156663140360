// UserAgent helper
require('cssuseragent');
// Lazy Load
const polyfillObserver = require('intersection-observer');
const lozad = require('lozad');
const imgObserver = lozad('[data-lazy]', {
    rootMargin: '150px 0px'
});
imgObserver.observe();
const lineTwoImg = lozad('.wysi-two-img img', {
    rootMargin: '50px 0px'
});
lineTwoImg.observe();

const lineThreeImg = lozad('.wysi-three-img img', {
    rootMargin: '50px 0px'
});
lineThreeImg.observe();
// Wowjs
const WOW = require('wowjs');
window.wow = new WOW.WOW({
    live: false
});
window.wow.init();
// HAMMERJS
const hammerjs = require('hammerjs');
// Backstretch
const backstretch = require('jquery-backstretch');
// Scrollpos-styler
require('scrollpos-styler');
// Flickity
require("flickity-fade");
// Fancybox
const fancybox = require("@fancyapps/fancybox");


$(function () {
    /*
        -- @ LINKS @ --
    */
    // External links
    $(document).on('click', 'a[href$=".pdf"], a.external-link', function (e) {
        e.preventDefault()
        window.open($(this).attr("href"))
    })
    // Disabled links
    $(document).on('click', 'a.noLink, a[href="GOTOLINK"]', function (e) {
        e.preventDefault()
    })

    // Backtotop links
    $(document).on('click', '.backtotop-article a', function (e) {
        e.preventDefault();
        $('body,html').animate({scrollTop: 0}, 500, 'swing');
    });

    $('.modal:not([data-disabled-overlay])')
        .find('.modal-overlay')
        .add('.modal *[data-close-modal]')
        .on('click', function (e) {
            $(this).parents('.modal').trigger('cc.modal.close');
        });


    /*
        -- @ MAIN MENU PHONE @ --
    */
    var isMenuOpened = false;
    var _closeMenus = function () {
        $('html, body').removeClass('opened-menu opened-submenu');
        $('#main-nav').find('.shown').removeClass('shown')
        $('.hamburger').removeClass('is-active')
        isMenuOpened = false;
    }
    var _openMenu = function () {
        $('html, body').addClass('opened-menu')
        $('.hamburger').addClass('is-active')
        isMenuOpened = true;
    }
    var _closeSubMenu = function (submenu) {
        $('body').removeClass('opened-submenu')
        submenu.removeClass('shown')
    }
    var _openSubMenu = function (submenu) {
        $('body').addClass('opened-submenu')
        submenu.addClass('shown')
    }

    // Click on Hamburger
    $(document).on('click', '[data-js-openmenu]', function (e) {
        e.preventDefault()

        if (isMenuOpened)
            _closeMenus();
        else
            _openMenu();
    });
    $(document).on('click', '[data-js-opensubmenus]', function (e) {
        e.preventDefault();
        _openSubMenu($(this).next('div'));
    });
    $(document).on('click', '[data-js-closesubmenus]', function (e) {
        e.preventDefault()
        _closeSubMenu($(this).parents('div'));
    });

    // HammerJS
    var hammerBottomMenu = new Hammer(document.getElementById('main-nav'));
    hammerBottomMenu.on('swipeleft', function (ev) {
        console.log('swipe left')
        _closeMenus();
    });

    // HammerJS
    if ($('#modal-glossary').length) {
        var hammerGlossary = new Hammer(document.getElementById('modal-glossary'));
        hammerGlossary.on('swiperight', function (ev) {
            $('#modal-glossary [data-close-modal]').trigger('click')
        });
    }

    // Specific for trip-listing page : Close video modal
    if ($('#modal-tirawa-presentation').length) {
        $('#modal-tirawa-presentation')
            .on('cc.modal.close', function () {
                var
                    videoFrame = $(this).find('iframe'),
                    videoSrc = videoFrame.attr('src');

                videoFrame.attr('src', videoSrc);
            });
    }

    /*
        -- @ BACKSTRETCH @ --
        * Attach responsive background-images to elements
    */
    // Backstretch 
    if ($('.backstretch').length) {
        if ($('body.homepage').length) {
            // Launch Backstretch with lazy load
            const backstretchObserver = lozad('.backstretch', {
                rootMargin: '50px 0px',
                loaded: function (el) {
                    let imgName = $(el).attr('data-img');

                    if (typeof imgName !== 'undefined' && imgName.length) {

                        $(el).backstretch(imgName);
                    }
                }
            });
            backstretchObserver.observe();

        } else {

            $('.backstretch').each(function (i, el) {
                var imgName = $(this).attr('data-img');

                if (imgName != '' || imgName !== 'undefined')
                    $(this).backstretch(imgName);
            });
        }
    }

    const wysimagesObserver = lozad('.wysimages figure', {
        rootMargin: '50px 0px',
        loaded: function (el) {

            // console.log('launch backstretch')
            // let imgName = $(el).find('img').attr('src');

            // if (typeof imgName !== 'undefined' && imgName.length) {
            //     $(el).backstretch(imgName);
            // }
        }
    });
    wysimagesObserver.observe();


    /*
        -- @ SCROLL @ --
    */
    $(document).on('click', '.pano-img .arrow a', function () {
        var _breadcrumbPosition = parseInt($('.breadcrumb').offset().top - 100);
        $('html, body').stop(true, true).animate({scrollTop: _breadcrumbPosition}, 500, function () {
        });
    });


    /*
        -- @ Modals @ --
    */
    /* Open modal */
    $(document).on('click', '.modal-open, [data-modal]', function (e) {
        e.preventDefault();

        var
            modalId = $(this).data('target') || $(this).data('modal'),
            $modal = $('#' + modalId);

        if ($modal.length) {

            $('html,body').addClass('opened-modal');
            $modal.addClass('active');

        } else {
            alert('ChuckCSS error : modal with attribute id="' + modalId + '" does not exist!');
        }
    });

    /* Close modal */
    $(document).on('click', '.modal:not([data-disabled-overlay]) .modal-overlay, .modal *[data-close-modal]', function (e) {
        e.preventDefault();

        var $modal = $(this).parents('.modal');


        if ($modal.hasClass('active'))
            $modal.removeClass('active');

        if (!$('.modal.active').length)
            $('html,body').removeClass('opened-modal');
    });


    /*
        -- @ FANCYBOX PLUGIN @ --
    */
    $('.wysi-two-img img').add('.wysi-three-img img').each(function (i, el) {
        var _img = $(this)
        _src = _img.attr('src'),
            _alt = _img.attr('alt');

        _img.wrap('<a href="' + _src + '" data-fancybox="gallery" data-caption="' + _alt + '"></a>')
    });


    $('[data-fancybox]').fancybox({
        loop: true,
        infobar: false,
        buttons: [
            "zoom",
            "share",
            "fullScreen",
            "close"
        ],
        transitionEffect: "slide",
        protect: true,
        lang: "fr",
        i18n: {
            fr: {
                CLOSE: "Fermer",
                NEXT: "Suivant",
                PREV: "Précédent",
                ERROR: "La photo ne peut pas être chargée, merci de réessayer plus tard.",
                FULL_SCREEN: "Plein écran",
                DOWNLOAD: "Télécharger",
                SHARE: "Partager sur les réseaux sociaux",
                ZOOM: "Zoomer la photo"
            }
        }
    });
});